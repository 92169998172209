import React, { ErrorInfo } from 'react'
import { NextPageContext } from 'next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Language } from 'generated/sdk'
import { Title } from 'components/title/title'
import { Text } from 'components/text/text'
import { useLocationContext } from 'components/location-provider/location-provider'
import { useSeoContext } from 'components/seo-provider/seo-provider'
import { SecondaryButton } from 'components/secondary-button/secondary-button'
import { NextLink } from 'components/next-link/next-link'
import { mediaBreakpointUpLg, mediaBreakpointDownXs, breakpoints } from 'theme/breakpoints'
import { notifyError } from 'services/sentry'

export type StatusCodes = 404 | 500

type Props = {
	statusCode?: StatusCodes
	isFullScreen?: boolean
	// 3 properties for Sentry ErrorBoundary fallback component
	error?: NextPageContext['err']
	info?: ErrorInfo
	clearError?: () => void
}

const ERROR_TEXT = {
	404: {
		[Language.Pt]: {
			title: 'Página não encontrada',
			body: 'Lamentamos, mas a página que procura não se encontra disponível.',
			cta: 'VOLTAR Porto.',
		},
		[Language.En]: {
			title: 'Page not found',
			body: 'Sorry, the page you are looking for is not available.',
			cta: 'BACK Porto.',
		},
	},
	500: {
		[Language.Pt]: {
			title: 'Erro do servidor',
			body: 'Ocorreu um erro inesperado. Estamos a tentar resolver o problema.',
			cta: 'Tentar Novamente',
		},
		[Language.En]: {
			title: 'Server error',
			body: 'An unexpected error has occurred. We are trying to resolve the issue.',
			cta: 'Try again',
		},
	},
}

export const ErrorPageCol = styled.div<{ isFullScreen?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: ${props => (props.isFullScreen ? '100vh' : 'auto')};
	padding-top: 132px;
	padding-bottom: 132px;

	@media (orientation: landscape) and (max-height: ${breakpoints.sm}) {
		padding-top: 72px;
		padding-bottom: 72px;
	}

	${mediaBreakpointUpLg} {
		height: ${props => (props.isFullScreen ? '100vh' : 'auto')};
		padding-top: 178px;
		padding-bottom: 178px;
	}
`

const ErrorWrapper = styled.div`
	background-image: linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(15 47 127 / 8%) 100%);
`

const ErrorTitle = styled(Title)`
	margin: 0 0 8px;
	line-height: 30px;
	color: ${props => props.theme.text.secondary};

	${mediaBreakpointUpLg} {
		margin: 0 0 4px;
		line-height: 57px;
	}

	${mediaBreakpointDownXs} {
		font-size: 24px;
		line-height: 30px;

		span {
			display: none;
		}
	}
`

const ErrorBody = styled(Text)`
	margin-bottom: 24px;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;

	${mediaBreakpointUpLg} {
		margin-bottom: 48px;
		line-height: 12px;
	}

	${mediaBreakpointDownXs} {
		font-size: 16px;
	}
`

export const ErrorPage = ({ statusCode = 500, isFullScreen = true, error }: Props) => {
	const { language } = useLocationContext()
	const seoData = useSeoContext()
	const { asPath } = useRouter()
	const is404 = statusCode === 404
	const status = is404 ? 404 : 500
	const href = is404 ? `/${language}/` : asPath

	if (error) {
		notifyError(error)
	}

	return (
		<ErrorWrapper>
			<div className="container">
				<div className="row">
					<NextSeo
						title={ERROR_TEXT[status][language].title}
						titleTemplate={`%s - ${seoData.title}`}
						nofollow
						noindex
					/>
					<ErrorPageCol className="col" isFullScreen={isFullScreen}>
						<ErrorTitle>
							<span>{status} - </span>
							{ERROR_TEXT[status][language].title}
						</ErrorTitle>
						<ErrorBody>{ERROR_TEXT[status][language].body}</ErrorBody>
						{is404 ? (
							<NextLink href={href}>
								<SecondaryButton isLowercase={true}>{ERROR_TEXT[status][language].cta}</SecondaryButton>
							</NextLink>
						) : (
							<a href={href}>
								<SecondaryButton isLowercase={true}>{ERROR_TEXT[status][language].cta}</SecondaryButton>
							</a>
						)}
					</ErrorPageCol>
				</div>
			</div>
		</ErrorWrapper>
	)
}
