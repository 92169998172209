import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const SvgArrowDownPath = styled.path`
	stroke: ${props => props.theme.color.primary};
`

const TITLE_TEXT = {
	[Language.Pt]: (inverted = false) => (inverted ? 'Seta para cima' : 'Seta para baixo'),
	[Language.En]: (inverted = false) => (inverted ? 'Arrow up' : 'Arrow down'),
}

export const SvgArrowDown: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string; rotate?: number }
> = ({ suffix, customTitle, rotate = 0, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-arrow-right' + suffix

	return (
		<svg width={18} height={18} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language](rotate === 180)}</title>
			<SvgArrowDownPath
				transform={`rotate(${rotate} 9 9)`}
				d="M13.5 6.5l-4.5 5-4.5-5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
