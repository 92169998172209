import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { darkTheme } from 'theme/theme'
import { Logo } from 'components/logo/logo'
import { SvgFacebook } from 'components/svgs/svg-facebook'
import { SvgYouTube } from 'components/svgs/svg-youtube'
import { SvgTwitter } from 'components/svgs/svg-twitter'
import { SvgInstagram } from 'components/svgs/svg-instagram'
import { Text, Paragraph } from 'components/text/text'
import { splitArrayIntoColumns } from 'utils/arrays'
import { positionAbsoluteAndStretch, bgPortoPattern, ellipses, borderRgbaWithHex, transition } from 'theme/utils'
import { SvgArrowDown } from 'components/svgs/svg-arrow-down'
import { NextLink } from 'components/next-link/next-link'
import { FooterDesktopProps } from 'components/footer-desktop/footer-desktop'

enum ColumnsIndexes {
	PRIMARY,
	SECONDARY,
	OTHER,
}
type FooterMobileProps = FooterDesktopProps

const FooterMobileWrapper = styled.div`
	position: relative;
	padding: 23px 0 18px;
	background: ${props => props.theme.colors.twilightBlue};

	&::before {
		${positionAbsoluteAndStretch}
		content: ' ';
		${bgPortoPattern}
		background-position: left top;
		background-size: auto 550px;
	}
`

const LogoWrapper = styled.div`
	margin-bottom: 24px;
	text-align: center;
`

const SocialLinks = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	text-align: center;
`

const LinksListWrapper = styled.div<{ isOpen: boolean }>`
	padding: 16px 0;
	${p => borderRgbaWithHex('1px solid', p.theme.colors.lightPeriwinkle, p.isOpen ? 0 : 0.4, 'border-bottom')}
	transition: ${transition('border')};

	${Paragraph} {
		margin-bottom: 0;
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		text-transform: uppercase;
	}
`

const LinksListHeader = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;

	${Paragraph} {
		padding-top: 3px;
		line-height: 16px;
	}

	svg {
		margin-bottom: 1px;
		opacity: 0.7;
	}
`

const LinksList = styled.ul`
	padding: 8px 0 0;
	margin: 0;
	list-style: none;

	ul {
		padding: 0;
		margin: 0 0 16px;
		list-style: none;

		li {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	${Paragraph} {
		font-size: 16px;
		font-weight: 500;
		line-height: 19px;
		text-transform: uppercase;
	}
`

const LinksListCollapsible = styled.nav<{ isOpen: boolean; innerHeight: number }>`
	max-height: ${props => (props.isOpen ? props.innerHeight + 'px' : 0)};
	overflow: hidden;
	transition: ${transition('all')};
`

const LinksListItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 16px;
	}

	ul {
		margin-top: 8px;
	}
`

const LinksListItemAnchor = styled(NextLink)<{ subLink?: boolean; privacyLink?: boolean }>`
	position: relative;
	display: block;
	padding-top: 3px;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	color: ${props => props.theme.text.primary};
	opacity: ${props => (props.subLink ? 0.7 : 1)};
	transition: ${transition('opacity')};
	${ellipses};

	${props => !props.privacyLink && `max-width: calc(100% - 24px);`}

	&:hover {
		color: ${props => props.theme.text.primary};
		text-decoration: none;
		opacity: 1;
	}
`

const LinksAnchorListItemAnchor = styled.a<{ subLink?: boolean }>`
	display: block;
	max-width: calc(100% - 24px);
	padding-top: 3px;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	color: ${props => props.theme.text.primary};
	opacity: ${props => (props.subLink ? 0.7 : 1)};
	transition: ${transition('opacity')};
	${ellipses}

	&:hover {
		color: ${props => props.theme.text.primary};
		text-decoration: none;
		opacity: 1;
	}
`

const SecondaryLinksColumnsList = styled.nav<{ isOpen: boolean; innerHeight: number }>`
	display: flex;
	max-height: ${props => (props.isOpen ? props.innerHeight + 'px' : 0)};
	overflow: hidden;
	transition: ${transition('all')};

	> ul {
		width: 50%;
	}
`

const LinkAnchor = styled.a`
	position: relative;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	color: ${props => props.theme.text.primary};
	opacity: 0.8;

	&:hover {
		color: ${props => props.theme.text.primary};
		text-decoration: none;
		opacity: 1;
	}
`

const CopyrightWrapper = styled.div`
	${Paragraph} {
		margin-bottom: 0;
		font-weight: 500;
		opacity: 0.8;
	}
`

const CopyrightLinksList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0;
	margin: 38px 0 50px;
	list-style: none;
`

const CopyrightLinksItem = styled.li`
	position: relative;
	padding: 0;
	margin: 0 0 0 20px;

	&::before {
		position: absolute;
		top: 50%;
		left: -11px;
		width: 4px;
		height: 4px;
		content: ' ';
		background-color: ${props => props.theme.colors.white};
		border-radius: 50%;
		transform: translateY(-50%);
	}

	&:first-child {
		margin: 0;

		&::before {
			content: none;
		}
	}
`

const CopyrightTextWrapper = styled.div`
	${Paragraph} {
		text-align: center;
	}
`

const LinkWrapper = ({ children, href }: { children: React.ReactNode; href: string }) => (
	<LinkAnchor href={href} target="_blank" rel="noreferrer">
		<>{children}</>
	</LinkAnchor>
)

const FooterMobileComponent = ({
	social,
	primaryLinks,
	primaryLinksTitle,
	secondaryLinks,
	secondaryLinksTitle,
	otherLinks,
	otherLinksTitle,
	copyright,
	policiesLinks,
}: FooterMobileProps) => {
	const [currentColumnIndex, setCurrentColumnIndex] = useState<ColumnsIndexes | null>(null)

	const toggleOpenColumnIndex = (index: ColumnsIndexes) => {
		setCurrentColumnIndex(index === currentColumnIndex ? null : index)
	}

	const isPrimaryColumnOpen = currentColumnIndex === ColumnsIndexes.PRIMARY
	const isSecondaryColumnOpen = currentColumnIndex === ColumnsIndexes.SECONDARY
	const isOtherColumnOpen = currentColumnIndex === ColumnsIndexes.OTHER

	const secondaryLinksColumns = splitArrayIntoColumns(secondaryLinks, 2, 'subLinks')

	const primaryColumnInnerHeight = primaryLinks.length * 34 + 8
	const secondaryColumnInnerHeight = Math.max(
		...secondaryLinksColumns.map(column => {
			const columnCount = column.reduce((count, item) => {
				const subCount = item.subLinks ? item.subLinks.length * 27 + 16 : 0
				return count + (item.subLinks ? 19 : 34) + subCount
			}, 0)
			return columnCount
		})
	)
	const otherColumnInnerHeight = otherLinks.length * 34 + 8

	return (
		<ThemeProvider theme={darkTheme}>
			<FooterMobileWrapper>
				<div className="container">
					<div className="row">
						<LogoWrapper className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<Logo suffix="-footer-mobile" />
						</LogoWrapper>
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<LinksListWrapper isOpen={isSecondaryColumnOpen}>
								<LinksListHeader role="button" onClick={() => toggleOpenColumnIndex(ColumnsIndexes.SECONDARY)}>
									<Text>{secondaryLinksTitle}</Text>
									<SvgArrowDown suffix="-footer-mobile" rotate={isSecondaryColumnOpen ? 180 : 0} />
								</LinksListHeader>
								<SecondaryLinksColumnsList isOpen={isSecondaryColumnOpen} innerHeight={secondaryColumnInnerHeight}>
									{secondaryLinksColumns.map((column, idx) => (
										<LinksList key={idx}>
											{column.map((link, index) => {
												return (
													<LinksListItem key={index}>
														<LinksListItemAnchor href={link.href}>
															<span title={link.label}>{link.label}</span>
														</LinksListItemAnchor>
														{link.subLinks && (
															<ul>
																{link.subLinks.map((subLink, i) => (
																	<li key={i}>
																		<LinksListItemAnchor href={subLink.href} passHref subLink>
																			<span title={subLink.label}>{subLink.label}</span>
																		</LinksListItemAnchor>
																	</li>
																))}
															</ul>
														)}
													</LinksListItem>
												)
											})}
										</LinksList>
									))}
								</SecondaryLinksColumnsList>
							</LinksListWrapper>
						</div>
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<LinksListWrapper isOpen={isPrimaryColumnOpen}>
								<LinksListHeader role="button" onClick={() => toggleOpenColumnIndex(ColumnsIndexes.PRIMARY)}>
									<Text>{primaryLinksTitle}</Text>
									<SvgArrowDown suffix="-footer-mobile-primary" rotate={isPrimaryColumnOpen ? 180 : 0} />
								</LinksListHeader>
								<LinksListCollapsible isOpen={isPrimaryColumnOpen} innerHeight={primaryColumnInnerHeight}>
									<LinksList>
										{primaryLinks.map((link, index) => (
											<LinksListItem key={index}>
												<LinksListItemAnchor href={link.href} passHref>
													<span title={link.label}>{link.label}</span>
												</LinksListItemAnchor>
											</LinksListItem>
										))}
									</LinksList>
								</LinksListCollapsible>
							</LinksListWrapper>
						</div>
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<LinksListWrapper isOpen={isOtherColumnOpen}>
								<LinksListHeader role="button" onClick={() => toggleOpenColumnIndex(ColumnsIndexes.OTHER)}>
									<Text>{otherLinksTitle}</Text>
									<SvgArrowDown suffix="-footer-mobile-other" rotate={isOtherColumnOpen ? 180 : 0} />
								</LinksListHeader>
								<LinksListCollapsible isOpen={isOtherColumnOpen} innerHeight={otherColumnInnerHeight}>
									<LinksList>
										{otherLinks.map((link, index) => {
											const isRelativeUrl = link.href.startsWith('/')
											return (
												<LinksListItem key={index}>
													<LinksListItemAnchor href={link.href} passHref>
														{isRelativeUrl ? (
															<span title={link.label}>{link.label}</span>
														) : (
															<LinksAnchorListItemAnchor>{link.label}</LinksAnchorListItemAnchor>
														)}
													</LinksListItemAnchor>
												</LinksListItem>
											)
										})}
									</LinksList>
								</LinksListCollapsible>
							</LinksListWrapper>
						</div>
					</div>
					<CopyrightWrapper className="row">
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<SocialLinks>
								{social.facebook && (
									<LinkWrapper href={social.facebook}>
										<SvgFacebook suffix="-footer-mobile" />
									</LinkWrapper>
								)}
								{social.youtube && (
									<LinkWrapper href={social.youtube}>
										<SvgYouTube suffix="-footer-mobile" />
									</LinkWrapper>
								)}
								{social.twitter && (
									<LinkWrapper href={social.twitter}>
										<SvgTwitter suffix="-footer-mobile" />
									</LinkWrapper>
								)}
								{social.instagram && (
									<LinkWrapper href={social.instagram}>
										<SvgInstagram suffix="-footer-mobile" />
									</LinkWrapper>
								)}
							</SocialLinks>
						</div>
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<nav>
								<CopyrightLinksList>
									{policiesLinks.map((item, index) => (
										<CopyrightLinksItem key={index}>
											<LinksListItemAnchor href={item.href} privacyLink subLink>
												<>{item.label}</>
											</LinksListItemAnchor>
										</CopyrightLinksItem>
									))}
								</CopyrightLinksList>
							</nav>
						</div>
						<div className="col-md-10 offset-md-1 col-lg-12 offset-lg-0">
							<CopyrightTextWrapper>
								<Text>
									<span dangerouslySetInnerHTML={{ __html: copyright }} />
								</Text>
							</CopyrightTextWrapper>
						</div>
					</CopyrightWrapper>
				</div>
			</FooterMobileWrapper>
		</ThemeProvider>
	)
}

export const FooterMobile = React.memo(FooterMobileComponent)
