import React, { createRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

type StickyProps = {
	children: PropTypes.ReactNodeLike
	initialHeight?: number
}

const StickyWrapper = styled.div<{ $customHeight: number }>`
	position: relative;
	width: 100%;
	padding-bottom: ${props => `${props.$customHeight}px`};
`

const StickyElement = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
`

export const Sticky = ({ children, initialHeight = 0 }: StickyProps) => {
	const ref = createRef<HTMLDivElement>()
	const [height, setHeight] = useState(initialHeight)

	useEffect(() => {
		setHeight(ref.current?.getBoundingClientRect().height || 0)
	}, [ref])

	return (
		<StickyWrapper $customHeight={height}>
			<StickyElement ref={ref}>{children}</StickyElement>
		</StickyWrapper>
	)
}
