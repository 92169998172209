import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useRouter } from 'next/router'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Logo } from 'components/logo/logo'
import { PrimaryNavigation, PrimaryNavigationProps } from 'components/primary-navigation/primary-navigation'
import { SearchInput } from 'components/search-input/search-input'
import { Language } from 'generated/sdk'
import { Weather, WeatherProps } from 'components/weather/weather'
import { LanguageChooser, LanguageChooserProps } from 'components/language-chooser/language-chooser'
import { SecondaryNavigation, SecondaryNavigationProps } from 'components/secondary-navigation/secondary-navigation'
import { darkTheme } from 'theme/theme'
import { boxShadowRgbaWithHex, borderRgbaWithHex } from 'theme/utils'
import { mediaBreakpointUpXl } from 'theme/breakpoints'
import { removeTrailingSlash } from 'utils/url'
import { notifyError } from 'services/sentry'

export type PrimaryHeaderDesktopProps = {
	primaryLinks: PrimaryNavigationProps['links']
	languages: LanguageChooserProps['languages']
	secondaryLinks: SecondaryNavigationProps['links']
	secondaryLinksTitle?: string
	weather: WeatherProps
}

const HeaderTopWrapper = styled.div`
	background-color: ${props => props.theme.colors.twilightBlue};
`

const HeaderBottomWrapper = styled.div`
	background-color: ${props => props.theme.colors.paleGrey};
	${props => borderRgbaWithHex('solid 1px', props.theme.colors.black, 0.16, 'border-bottom')}
	${props => boxShadowRgbaWithHex('0 2px 8px 0', props.theme.colors.black, 0.04)}
`

const HeaderInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 1440px;
	padding: 15px 32px 14px;
	margin: 0 auto;
`

const HeaderSide = styled.div`
	display: flex;
	align-items: center;
`

const HeaderLogo = styled(Logo)`
	margin-right: 36px;
`

const HeaderSearch = styled(SearchInput)`
	width: 188px;
	margin-right: 34px;

	${mediaBreakpointUpXl} {
		width: 288px;
	}
`

const HeaderWeather = styled(Weather)`
	position: relative;
	top: -1px;
	margin-right: 32px;
`

const HeaderLanguageChooser = styled(LanguageChooser)`
	position: relative;
	top: 1px;
`

const PrimaryHeaderDesktopComponent = ({
	primaryLinks,
	languages,
	secondaryLinks,
	secondaryLinksTitle,
	weather,
	...props
}: PrimaryHeaderDesktopProps) => {
	const { language } = useLocationContext()
	const router = useRouter()
	const searchPath = language === Language.En ? '/en/search/' : '/pt/pesquisa/'

	const pushNewRoute = (pathname: string, query = {}) => {
		router
			.push(
				{
					pathname: '/[...slug]',
					query,
				},
				{
					pathname: removeTrailingSlash(pathname),
					query,
				}
			)
			.then(() => {
				if (typeof window !== 'undefined') window.scrollTo(0, 0)
			})
			.catch(error => notifyError(error))
	}

	const handleSearch = (searchTerm: string) => {
		pushNewRoute(searchPath, searchTerm && { searchQuery: searchTerm })
	}

	return (
		<div {...props}>
			<ThemeProvider theme={darkTheme}>
				<HeaderTopWrapper>
					<HeaderInner>
						<HeaderSide>
							<HeaderLogo suffix="-primary-header-desktop" />
							<PrimaryNavigation links={primaryLinks} />
						</HeaderSide>
						<HeaderSide>
							<HeaderSearch compressed handleSearch={handleSearch} />
							<HeaderWeather {...weather} suffix="-primary-header-desktop" />
							<HeaderLanguageChooser languages={languages} />
						</HeaderSide>
					</HeaderInner>
				</HeaderTopWrapper>
			</ThemeProvider>

			<HeaderBottomWrapper>
				<SecondaryNavigation links={secondaryLinks} />
			</HeaderBottomWrapper>
		</div>
	)
}

export const PrimaryHeaderDesktop = React.memo(PrimaryHeaderDesktopComponent)
