import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { LogoLink, Logo } from 'components/logo/logo'
import { WeatherWidget, Weather, WeatherProps } from 'components/weather/weather'
import { LanguageChooserLink, Language, LanguageChooser } from 'components/language-chooser/language-chooser'
import { SvgClose } from 'components/svgs/svg-close'
import { darkTheme } from 'theme/theme'
import { transition } from 'theme/utils'
import { mediaBreakpointUpSm } from 'theme/breakpoints'
import { SvgHamburger } from 'components/svgs/svg-hamburger'
import { MenuBurger } from 'components/menu-burger/menu-burger'
import { LinkItem } from 'components/primary-navigation/primary-navigation'
import { SecondaryLinkItem } from 'components/secondary-navigation/secondary-navigation'
import { useRouteChange } from 'hooks/use-route-change'
import { NextLink } from 'components/next-link/next-link'

export type PrimaryHeaderMobileProps = {
	languages: Language[]
	primaryLinks: LinkItem[]
	secondaryLinksTitle: string
	secondaryLinks: SecondaryLinkItem[]
	weather: WeatherProps
}

const PrimaryHeaderMobileWrapper = styled.div`
	position: relative;
	background: ${props => props.theme.colors.twilightBlue};
`

const PrimaryHeaderMobileMenuBurgerWrapper = styled.div<{ isOpen: boolean }>`
	position: fixed;
	inset: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	transition: all 0.6s cubic-bezier(0.76, 0.01, 0.26, 0.99);
	transform: ${props => (props.isOpen ? 'translateX(0%)' : 'translateX(100%)')};
`

const PrimaryHeaderMobileHeader = styled.header`
	position: relative;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 16px 7px;

	${LogoLink} {
		padding: 0;
	}
`

const PrimaryHeaderMobileHeaderRight = styled.div`
	display: flex;
	align-items: center;

	${WeatherWidget} {
		margin-right: 16px;
	}

	${LanguageChooserLink} {
		padding-top: 3px;
	}
`

const PrimaryHeaderMobileClose = styled.button`
	display: block;
	height: 24px;
	padding: 0;
	margin-right: -4px;
	margin-left: 16px;
	overflow: hidden;
	user-select: none;
	background: transparent;
	border: none;

	&:focus {
		outline: none;
	}
`

const PrimaryHeaderMobileNavList = styled.ul`
	display: flex;
	justify-content: space-between;
	padding: 7px 15px 11px;
	margin: 0;
	list-style: none;

	${mediaBreakpointUpSm} {
		justify-content: center;
	}
`

const PrimaryHeaderMobileNavItem = styled.li`
	padding: 0;
	margin: 0;

	${mediaBreakpointUpSm} {
		margin-left: 28px;
	}

	&:first-child {
		margin-left: 0;
	}
`

const PrimaryHeaderMobileNavLink = styled(NextLink)`
	font-size: 14px;
	font-weight: 700;
	line-height: 15px;
	color: ${props => props.theme.color.primary};
	transition: ${transition('color')};

	&:hover,
	&:focus,
	&:active {
		color: ${props => props.theme.color.secondary};
		text-decoration: none;
	}
`

const PrimaryHeaderMobileComponent = ({
	languages,
	primaryLinks,
	secondaryLinksTitle,
	secondaryLinks,
	weather,
}: PrimaryHeaderMobileProps) => {
	const [isMenuBurgerOpen, setIsMenuBurgerOpen] = useState(false)
	useRouteChange(() => setIsMenuBurgerOpen(false))

	useEffect(() => {
		if (isMenuBurgerOpen) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [isMenuBurgerOpen])

	return (
		<ThemeProvider theme={darkTheme}>
			<PrimaryHeaderMobileWrapper>
				<PrimaryHeaderMobileHeader>
					<Logo suffix="-primary-header-mobile" />
					<PrimaryHeaderMobileHeaderRight>
						<Weather {...weather} suffix="-primary-header-mobile" />
						<LanguageChooser languages={languages} />
						<PrimaryHeaderMobileClose onClick={() => setIsMenuBurgerOpen(!isMenuBurgerOpen)}>
							{isMenuBurgerOpen ? (
								<SvgClose suffix="-primary-header-mobile" />
							) : (
								<SvgHamburger suffix="-primary-header-mobile" />
							)}
						</PrimaryHeaderMobileClose>
					</PrimaryHeaderMobileHeaderRight>
				</PrimaryHeaderMobileHeader>
				<PrimaryHeaderMobileMenuBurgerWrapper isOpen={isMenuBurgerOpen}>
					<MenuBurger
						primaryLinks={primaryLinks}
						secondaryLinks={secondaryLinks}
						secondaryLinksTitle={secondaryLinksTitle}
					/>
				</PrimaryHeaderMobileMenuBurgerWrapper>
				<nav>
					<PrimaryHeaderMobileNavList>
						{primaryLinks.slice(0, 4).map((navItem, index) => (
							<PrimaryHeaderMobileNavItem key={index}>
								<PrimaryHeaderMobileNavLink href={navItem.href} passHref>
									<>{navItem.label}</>
								</PrimaryHeaderMobileNavLink>
							</PrimaryHeaderMobileNavItem>
						))}
					</PrimaryHeaderMobileNavList>
				</nav>
			</PrimaryHeaderMobileWrapper>
		</ThemeProvider>
	)
}

export const PrimaryHeaderMobile = React.memo(PrimaryHeaderMobileComponent)
